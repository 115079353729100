import React from 'react';
import Layout from '../components/Layout';
import logo from '../assets/images/logo.png';

import pic1 from '../assets/images/pic01.jpg';
import pic2 from '../assets/images/pic02.jpg';
import pic3 from '../assets/images/pic03.jpg';
import pic4 from '../assets/images/el_jefe_console.jpg';
import pic5 from '../assets/images/crb_logo1.png';
import pic6 from '../assets/images/hugh_fadal3.jpg';
import pic7 from '../assets/images/jdcravensII.png';
import pic8 from '../assets/images/bg_crb.jpg';
import pic9 from '../assets/images/hugh_fadal_band.jpg';
import pic10 from '../assets/images/Colorado_River_Boys_Live.png';
import config from '../../config';

import '../assets/sass/main.scss';
import Player from '../components/Player';

const IndexPage = () => (
  <div>
  <Layout>
    <section id="banner">
      <div className="inner">
        <div className="logo">
          <img src={logo} alt="Sessions West Logo" />
        </div>
      </div>
    </section>

    <section id="wrapper">
      <section id="four" className="wrapper style1">
        <div className="inner">
          <h2 className="major">Artists</h2>
          <section className="features">
            <article>
              <a href="/artists/jeff-eljefe-anderson" className="image">
                <img src={pic4} alt="Jeff 'El Jefe' Anderson" />
              </a>
              <h3 className="major">Jeff 'El Jefe' Anderson</h3>
              <p>
                Legendary, South Texas Bluesman and founder of Sessions West.
                Jeff is the owner of Jeff Andersons Enterprises Record Label and
                has released 6 albums thhroughout his storied career.
              </p>
              <a href="/artists/jeff-eljefe-anderson" className="special">
                Learn more
              </a>
            </article>
            <article>
              <a href="/artists/jesse-cravens" className="image">
                <img src={pic7} alt="" />
              </a>
              <h3 className="major">J.D. Cravens</h3>
              <p>Singer Songwriter, Bass Player, Producer based out of Austin, TX.</p>
              <a href="/artists/jesse-cravens" className="special">
                Learn more
              </a>
            </article>
            <article>
              <a href="/artists/colorado-river-boys" className="image">
                <img src={pic10} alt="" />
              </a>
              <h3 className="major">Colorado River Boys</h3>
              <p>Country Music straight outta Volente, TX.</p>
              <a href="/artists/colorado-river-boys" className="special">
                Learn more
              </a>
            </article>
            <article>
              <a href="/artists/hugh-fadal-band" className="image">
                <img src={pic9} alt="" />
              </a>
              <h3 className="major">Hugh Fadal Band</h3>
              <p>
                A professional hunting/fishing guide and singer/songwriter, many
                of his songs were inspired while frequenting some of his home
                state's finest ranches and rivers.
              </p>
              <a href="/artists/hugh-fadal-band" className="special">
                Learn more
              </a>
            </article>
          </section>
        </div>
      </section>
    </section>
  </Layout>
  </div>
);

export default IndexPage;
